// translationMixin.js
import { api } from '@/services'
export default {
  methods: {
    doSystemMessageTranslation (message) {
      /*
      if (this.traductAlready) {
        console.log('from translated')
        this.traduct = this.traductAlready
        this.traductAlready = ''
        return
      }
      */
      return api.post('chat/translate', { message, language: 'Inglês', message_id: this.translateId }).then((response) => {
        console.log('test')
        this.traduct = response.data
        // message.isLoadingTranslate = false
      })
    },

    doTranslation (message) {
      /*
      if (this.traductAlready) {
        console.log('from translated')
        this.traduct = this.traductAlready
        this.traductAlready = ''
        return
      }
      */
      return api.post('chat/translate', { message, language: this.ptbr ? 'Português' : 'Inglês', message_id: this.translateId }).then((response) => {
        this.traduct2 = response.data
        this.traduct[message] = response.data
        // message.isLoadingTranslate = false
      })
    }
  }
}
