<!-- Microphone.vue -->
<template>
    <div>
      <i ref="button-talk" :class="isListening? 'material-icons scale-up-center text-danger':'material-icons microphone'" @click="toggleListening">
        {{ isListening ? 'send' : 'mic' }}
      </i>
      <b-tooltip v-if="!isListening" :target="() => $refs['button-talk']" :title="!isListening? 'Clique para falar!': 'Clique para enviar!'"></b-tooltip>
    </div>
  </template>

<script>
export default {
  props: {
    isListening: Boolean,
    toggleListening: Function
  }
}
</script>

  <style scoped>
  /* Add styles for the microphone component */
  .microphone {
    width: 4rem;
    height: 4rem;
    font-size: 3rem;
    cursor: pointer;
    padding: 5%;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
  .scale-up-center {
    font-size: 3rem;
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    -webkit-animation: scale-up-center 0.6s ease-in-out infinite alternate both;
    animation: scale-up-center 0.6s ease-in-out infinite alternate both;
  }
 /* ----------------------------------------------
 * Generated by Animista on 2024-3-27 15:56:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
  </style>
